<template>
    <b-row class="justify-content-center">
        <b-col md="6">
            <b-card>
                <b-form v-on:submit.prevent>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group
                            label="Department Name"
                            label-for="departments"
                            >
                                <b-form-input
                                    id="departments"
                                    placeholder="Department Name"
                                    v-model="form.departments"
                                    required
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                            label="Organization"
                            label-for="organizations"
                            >
                                <b-form-select
                                id="organizations"
                                v-model="selectedOrganization"
                                :options="optionsOrganization"
                                @change="trigger(selectedOrganization)"
                                required
                                />
                                <!-- <b-card-text class="mt-1 mb-0">
                                Selected: <strong>{{ selectedOrganization }}</strong>
                                </b-card-text> -->
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                            label="Division"
                            label-for="divisions"
                            >
                                <b-form-select
                                id="divisions"
                                v-model="selectedDivision"
                                :options="optionsDivision"
                                required
                                />
                                <!-- <b-card-text class="mt-1 mb-0">
                                Selected: <strong>{{ selectedDivision }}</strong>
                                </b-card-text> -->
                            </b-form-group>
                        </b-col>

                        <!-- submit and reset -->
                        <b-col md="12" class="mt-2">
                            <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="primary"
                            class="mr-1"
                            @click="addDepartment()"
                            >
                            Submit
                            </b-button>
                            <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="reset"
                            variant="outline-secondary"
                            >
                            Reset
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BFormSelect, BListGroupItem, BAvatar, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        ToastificationContent,
        BFormSelect,
        BListGroupItem,
        BAvatar,
        BCard,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        draggable,
        Prism
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            form: {
                departments: ''
            },
            selectedOrganization: null,
            optionsOrganization: [],
            selectedDivision: null,
            optionsDivision: []
        }
    },
    created() {
        this.$http.get('organizations')
        .then(res => { 
            this.optionsOrganization.push({value: null, text: 'Please select 1 organization'})
            
            var datas = res.data.data
            var i
            for(i=0; i<datas.length; i++) {
                var $data = {
                    value: datas[i].id,
                    text: datas[i].name
                }
                this.optionsOrganization.push($data)
            }
            // console.log(this.optionsOrganization)
        });
    },
    methods: {
        trigger(id) {
            this.$http.get("divisions/all?organization_id="+id)
            .then(res => { 
                this.optionsDivision = [];
                this.selectedDivision = null;
                this.optionsDivision.push({value: null, text: 'Please select 1 division'})
                
                var datas = res.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.optionsDivision.push($data)
                }
                // console.log(this.optionsDivision)
            });
        },
        addDepartment() {
            this.$http
                .post("departments/add", {
                    "name": this.form.departments,
                    "division_id": this.selectedDivision
                })
                .then((response) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Notification',
                            icon: 'BellIcon',
                            text: 'Success add Department',
                            variant: 'success',
                        },
                    })
                    location.href = "/settings/departments"
                    // console.log(response.data.data)
                }).catch((errors) => {
                    this.errMessage = errors.response.data.message
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Notification',
                            icon: 'BellIcon',
                            text: this.errMessage,
                            variant: 'warning',
                        },
                    })
                    console.log(errors.response)
                });
        },
    },
}
</script>